<script>
import Layout from "@/router/layouts/franchise";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import Qty from "@/components/widgets/franchise-qty-points";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
    Qty,
  },
  data() {
    return {
      alert: {
        search: { type: "", message: "" },
      },

      search: {
        loading: false,
        username: "",
      },

      categories: null,
      products: null,

      item: {
        loading: false,
        category: "",
        product: "",
        quantity: 1,
      },

      cart: {
        user: null,
        items: {
          total: 0,
          list: null,
        },
        subtotal: "0.00",
        total: "0.00",
        points: "0.00",
      },

      checkout: {
        loading: false,
        errored: false,
        empty: false,
      },

      balance: {
        status: false,
        value: 0,
      },
    };
  },
  validations: {
    search: {
      username: { required },
    },
  },
  methods: {
    searchUser() {
      this.search.loading = true;

      api
        .post("franchise/points/user/search", {
          username: this.search.username,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.cart.user = response.data.user;

            this.getProducts();
            this.getCart();
          } else {
            this.$toast.error(response.data.message);
          }
        })
        .finally(() => {
          this.search.loading = false;
        });
    },
    changeUser() {
      api.post("franchise/points/user/change").then((response) => {
        if (response.data.status == "success") {
          this.cart.user = null;
          this.categories = null;
          this.products = null;

          this.getCart();
        }
      });
    },
    getProducts() {
      api.get("franchise/points/products").then((response) => {
        if (response.data.status == "success") {
          this.categories = response.data.categories;
          this.products = response.data.products;
        }
      });
    },
    getCart() {
      api.get("franchise/points/cart").then((response) => {
        if (response.data.status == "success") {
          this.cart = response.data;
        }
      });
    },
    addProduct() {
      api
        .post("franchise/points/cart", {
          id: this.item.product,
          qty: this.item.quantity,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.item.products = response.data.list;
            this.getCart();
          }
        });
    },
    checkoutCart() {
      this.checkout.loading = true;

      api
        .post("franchise/points/checkout", {
          balance: this.balance.status,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.getCart();

            this.$router.push(
              "/franchise/points/orders/" + response.data.order.id
            );
          } else {
            this.$toast.error(response.data.message);
          }
        })
        .catch((error) => {
          this.checkout.errored = error;
          this.checkout.loading = false;
          this.$toast.error(
            "Não foi possível finalizar sua compra, tente novamente."
          );
        })
        .finally(() => {
          this.checkout.loading = false;
        });
    },
  },
  computed: {
    getSubtotal() {
      return this.cart.subtotal;
    },
    getTotal() {
      return parseFloat(this.getSubtotal);
    },
    getToPay() {
      if (this.balance.status) {
        if (this.balance.value > 0) {
          if (this.balance.value >= this.getTotal) {
            return 0;
          } else {
            return this.getTotal - this.balance.value;
          }
        } else {
          return this.getTotal;
        }
      } else {
        return this.getTotal;
      }
    },
  },
  mounted() {
    this.getCart();
    this.getProducts();
  },
  watch: {
    "item.category": function () {
      this.item.product = "";
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Pontuações › Lançar</h4>
        </div>
      </div>
    </div>

    <div v-if="cart.user && !cart.user.id">
      <div class="card">
        <div class="card-body">
          <b-form class="p-3" @submit.prevent="searchUser">
            <div v-if="alert.search.message" :class="'alert ' + alert.search.type">
              {{ t(alert.search.message) }}
            </div>
            <b-form-group :label="t('Nome de Usuário')" label-for="username">
              <b-form-input v-model="search.username" type="text" :class="{ 'is-invalid': $v.search.username.$error }" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX'" autocomplete="off"></b-form-input>
              <div v-if="$v.search.username.$error" class="invalid-feedback">
                <span v-if="!$v.search.username.required">{{
                  t("Username is required.")
                  }}</span>
              </div>
            </b-form-group>
            <div class="mt-4">
              <b-button :disabled="search.loading == true || !this.search.username" type="submit" variant="default">
                Buscar
                <b-spinner v-if="search.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card">
        <div class="card-body">
          <div v-if="cart.user" class="d-flex">
            <div class="align-self-center mr-3">
              <img v-if="cart.user.avatar" class="rounded-circle header-profile-user" :src="cart.user.avatar" alt="" style="width: 60px; height: 60px" />
              <img v-else class="rounded-circle header-profile-user" :src="'https://ui-avatars.com/api/?background=0A3398&color=fff&name=' +
                cart.user.name
                " alt="" style="width: 60px; height: 60px" />
            </div>
            <h5 class="mb-0 flex-fill align-self-center font-size-16">
              <small>{{ cart.user.username }}</small><br />
              {{ cart.user.name }}
            </h5>
            <div class="mb-0 align-self-center text-right">
              <button class="btn btn-default btn-sm" v-on:click="changeUser">
                ALTERAR
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-4">
          <div class="card">
            <div class="card-body">
              <b-form class="p-3" @submit.prevent="addProduct">
                <b-form-group id="categories" :label="t('Categorias')" label-for="categories">
                  <select v-model="item.category" class="custom-select mb-2">
                    <option value="">– Selecione uma categoria</option>
                    <option v-for="(c, index) in categories" :key="index" :value="index">
                      {{ c }}
                    </option>
                  </select>
                </b-form-group>
                <b-form-group v-if="item.category" id="products" :label="t('Produtos')" label-for="products">
                  <select v-model="item.product" class="custom-select mb-2">
                    <option value="">– Selecione um produto</option>
                    <option v-for="(p, index) in products[item.category]" :key="index" :value="p.id">
                      {{ p.name }}
                    </option>
                  </select>
                </b-form-group>
                <b-form-group v-if="item.product" id="quantity" :label="t('Quantidade')" label-for="quantity">
                  <b-input-group>
                    <b-form-input id="quantity" v-model="item.quantity" type="number" min="1" autocomplete="off"></b-form-input>
                    <span class="btn btn-light border-1 rounded-0" v-on:click="item.quantity--"><i class="bx bx-minus-circle align-middle text-dark"></i></span>
                    <span class="btn btn-default border-1" style="
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                      " v-on:click="item.quantity++"><i class="bx bx-plus-circle align-middle text-white"></i></span>
                  </b-input-group>
                </b-form-group>
                <div v-if="item.category && item.product" class="d-flex bg-soft-light rounded p-3">
                  <template v-for="(p, index) in products[item.category]">
                    <div :key="'p-' + index" v-if="p.id == item.product" class="d-flex">
                      <div class="align-self-center">
                        <img v-if="products[item.category][index].image" style="width: 80px; height: 80px; object-fit: cover" class="rounded" :src="products[item.category][index].image" />
                      </div>
                      <div class="align-self-center flex-fill ml-3">
                        {{ products[item.category][index].name }}
                        <h4 class="m-0">
                          {{ products[item.category][index].price | currency }}
                        </h4>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="mt-4 text-right">
                  <b-button :disabled="this.item.loading == true" type="submit" variant="default">
                    {{ t("Adicionar") }}
                    <b-spinner v-if="item.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                  </b-button>
                </div>
              </b-form>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-8">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <div v-if="cart.items.total == 0" class="text-center">
                  Nenhum produto em seu carrinho.
                </div>
                <table v-else class="table table-centered table-nowrap">
                  <thead>
                    <tr>
                      <th style="width: 1%" class="border-0"></th>
                      <th class="border-0">Produto</th>
                      <th style="width: 1%" class="border-0 text-center">
                        Pontos
                      </th>
                      <th style="width: 1%" class="border-0 text-center">
                        Qtd
                      </th>
                      <th style="width: 1%" class="border-0 text-center">
                        Preço
                      </th>
                      <th style="width: 1%" class="border-0 text-center">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="cart.items.total > 0">
                    <tr v-for="(item, id) in cart.items.list" :key="id">
                      <td>
                        <img v-if="item.image" style="width: 50px; height: 50px; object-fit: cover" class="" :src="item.image" />
                      </td>
                      <td class="font-weight-medium">
                        {{ item.name }}
                      </td>
                      <td class="text-center">{{ item.points | points }}</td>
                      <td class="text-center">
                        <Qty :id="id" :quantity="item.quantity"></Qty>
                      </td>
                      <td class="text-center">{{ item.price | currency }}</td>
                      <td class="text-center">
                        {{ item.subtotal | currency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="cart.items.total > 0">
            <div class="card">
              <div class="card-body">
                <h5 class="mb-3">Resumo do Pedido</h5>
                <div class="d-flex mb-2">
                  <div class="flex-fill">
                    <span v-if="cart.items.total <= 1">
                      {{ cart.items.total }} produto
                    </span>
                    <span v-else-if="cart.items.total > 1">
                      {{ cart.items.total }} produtos
                    </span>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <div class="flex-fill">
                    <h6 class="mb-0">Pontos</h6>
                  </div>
                  <div class="flex-fill text-right">
                    <h6 class="mb-0">{{ cart.points | points }}</h6>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <div class="flex-fill">
                    <h6 class="mb-0">Total</h6>
                  </div>
                  <div class="flex-fill text-right">
                    <h6 class="mb-0">{{ getTotal | currency }}</h6>
                  </div>
                </div>
                <button v-on:click="checkoutCart()" class="btn btn-default btn-block font-weight-medium text-uppercase py-2" :disabled="checkout.loading">
                  <i class="bx bx-basket font-size-24 align-middle pb-1"></i>
                  Finalizar Compra
                  <b-spinner v-if="checkout.loading" small class="align-middle" variant="white" role="status"></b-spinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
